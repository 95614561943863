.radial-gradient {
  background: radial-gradient( at 48% 50% , #D61D3A -100%, #0F0F0F 50%);
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items:center;
}



/* html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
} */

:root {
  --duration: 1.5s;
  --container-size: 250px;
  --box-size: 33px;
  /* --box-border-radius: 15%; */
}

.container {
  width: var(--container-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.annimation {
  /* Previously --box-size */
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  transform-origin: -50% center;
}

.annimation:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.annimation:nth-child(1) {
  animation: flip-1 1.5s ease-in-out infinite alternate;
}

.annimation:nth-child(1):after {
  animation: squidge-1 1.5s ease-in-out infinite alternate;
}

.annimation:nth-child(2) {
  animation: flip-2 1.5s ease-in-out infinite alternate;
}

.annimation:nth-child(2):after {
  animation: squidge-2 1.5s ease-in-out infinite alternate;
}

.annimation:nth-child(3) {
  animation: flip-3 1.5s ease-in-out infinite alternate;
}

.annimation:nth-child(3):after {
  animation: squidge-3 1.5s ease-in-out infinite alternate;
}

.annimation:nth-child(4) {
  animation: flip-4 1.5s ease-in-out infinite alternate;
}

.annimation:nth-child(4):after {
  animation: squidge-4 1.5s ease-in-out infinite alternate;
}

.annimation:nth-child(5) {
  animation: flip-5 1.5s ease-in-out infinite alternate;
}

.annimation:nth-child(5):after {
  animation: squidge-5 1.5s ease-in-out infinite alternate;
}

.annimation:nth-child(6) {
  animation: flip-1 1.5s ease-in-out infinite alternate; 
}

.annimation:nth-child(6):after {
  animation: squidge-1 1.5s ease-in-out infinite alternate; 
}

.annimation:nth-child(7) {
  animation: slide 1.5s ease-in-out infinite alternate; 
}

.annimation:nth-child(7):after {
  animation: color-change 1.5s ease-in-out infinite alternate; 
}

@keyframes slide {
  0% {
    transform: translatex(0vw);
  }
  100% {
    transform: translatex(-160px); /* 250px - (33px * 1.25) */
  }
}

@keyframes color-change {
  /* Define as needed */
}

@keyframes flip-1 {
  0%, 0% { transform: rotate(0); }
  35%, 100% { transform: rotate(-180deg); }
}

@keyframes flip-2 {
  0%, 30% { transform: rotate(0); }
  50%, 100% { transform: rotate(-180deg); }
}

@keyframes flip-3 {
  0%, 45% { transform: rotate(0); }
  65%, 100% { transform: rotate(-180deg); }
}

@keyframes flip-4 {
  0%, 60% { transform: rotate(0); }
  80%, 100% { transform: rotate(-180deg); }
}

@keyframes flip-5 {
  0%, 75% { transform: rotate(0); }
  95%, 100% { transform: rotate(-180deg); }
}

@keyframes flip-6 {
  0%, 90% { transform: rotate(0); }
  100% { transform: rotate(-180deg); }
}

@keyframes squidge-1 {
  5% { transform-origin: center bottom; transform: scalex(1) scaley(1); }
  15% { transform-origin: center bottom; transform: scalex(1.3) scaley(0.7); }
  20%, 25% { transform-origin: center bottom; transform: scalex(0.8) scaley(1.4); }
  55%, 100% { transform-origin: center top; transform: scalex(1) scaley(1); }
}