.font-domine {
  font-family: 'Domine', serif;
}

.font-inter {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  font-family: 'Domine', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Hamburger Menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 24px;
  height: 2px;
  top: 0;
  left: 0;
  background: #fff;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle{
  transform: translateY(7px);
}
.hamburger-bottom{
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}

.step-card {
  position: relative;
}

.step-card::after {
  content: '';
  position: absolute;
  top: 0px;
  left: 50%;
  width: 5;
  height: 5;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 70px solid #27272A;
  transform: translateX(-50%);
}

::-webkit-scrollbar {
  background: #0F0F0F;
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #2C2B2B;
  border-radius: 6px;
}

.card-container {
  @apply hover:scale-105 transition duration-200 rounded-3xl max-w-80 border-2 border-[#2C2B2B] md:w-auto hover:shadow-md hover:shadow-red-600;
}

.card-flex {
  @apply flex flex-col justify-between p-5 space-y-3 h-full;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:active  {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}


@tailwind base;
@tailwind components;
@tailwind utilities;